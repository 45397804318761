/* eslint-disable max-len */
const messages = {
  hu: {
    'application.text1': 'Megéri nekem?',
    'application.text2': 'Miért érdemes az AppOn.cloud -ot használni?',
    'application.text3': 'A gyártó egyéb szoftverei',
    'application.whytouse': 'Miért érdemes az AppOn.cloud Premiumot használni?',
    'btn.casestudy': 'Esettanulmány',
    'btn.download': 'Letöltés',
    'btn.send': 'Küld',
    'btn.video': 'Videó',
    'contactform.formError': 'Hiba történt az űrlap elküldésekor',
    'contactform.formSent':
      'Kedves vásárló! Köszönjük, hogy elküldte kérdését. A lehető leghamarabb felvesszük Önnel a kapcsolatot.',
    'contactform.gdpr': ' ',
    'contactform.gdprError': 'required.',
    'contactform.gdprLink':
      'Hozzájárulok a kitöltött adatoknak a személyes adatok kezeléséhez való hozzájárulásában meghatározott mértékben és célból történő kezeléséhez',
    'contactform.mail': 'Email',
    'contactform.message': 'üzenet',
    'contactform.name': 'Név',
    'contactform.phone': 'Telefon',
    'contactform.validation.email': 'Írja be a @ karaktert az e -mail címébe. A címből hiányzik a @ karakter.',
    'contactform.validation.phone': 'Kérjük, adjon meg egy nemzetközi telefonszámot (+XXX XXX XXX XXX)',
    'contacts.form.title': 'Van kérdésük? írjon nekünk',
    'contacts.sales': 'Kereskedelem',
    'contacts.techsupport': 'Technikai támogatás',
    'contacts.title': 'Vegye fel velünk a kapcsolatot, több európai országban van képviseletünk',
    'cookies.preferences': 'A cookie-beállítások módosítása',
    'footer.sales': 'Kereskedelem',
    'footer.techsupport': 'Technikai támogatás',
    'homepage.advantage.title': 'Miért kell üzleti alkalmazásoknak lennie a felhőben?',
    'homepage.form.title': 'Megéri nekem?',
    'homepage.introduction.button': 'Támogatott alkalmazások',
    'homepage.introduction.title': 'Az alkalmazások legkényelmesebb tárolása',
    'homepage.support': 'Támogatjuk',
    'homepage.supportedapps.title': 'Milyen alkalmazásokat támogatunk?',
    'langswitcher.country': 'Válassza ki azt az országot, amelyben tartózkodik',
    'langswitcher.lang': 'Válasszon nyelvet',
    'navigation.contacts': 'Névjegyek',
    'navigation.customerportal': 'ügyfélportál',
    'navigation.forpress': 'A média számára',
    'navigation.references': 'Referencia',
    'navigation.services': 'Szolgáltatások',
    'navigation.support': 'Támogatás ',
    'navigation.supportedapps': 'Támogatot aplikációk',
    'premium.form.title': 'Érdekli az AppOn.cloud prémium? Írjon nekünk, és hamarosan felvesszük Önnel a kapcsolatot.',
    'premium.promo.cta': 'Érdekel egem',
    'premium.promo.text': 'Még nagyobb kényelem a vállalat számára',
    'pressrelease.form.title': 'Megéri nekem?',
    'pressrelease.text1': 'Cikkek listája',
    'pressrelease.text2': 'A sajtóközlemények listája',
    'pressrelease.title': 'Press release',
    'reference.form.title': 'Sok elégedett ügyfél használja szolgáltatásainkat. Légy köztük.',
    'reference.nextref': 'Egyéb referencia',
    'search.label': 'Keresés',
    'services.form.title': 'Érdekli ez a termék? Írjon nekünk, és hamarosan felvesszük Önnel a kapcsolatot.',
    'services.status': 'Szolgáltatások állapota',
    'services.title': 'Támogatott szolgáltatások',
    'supportedapps.subtitle':
      'Válassza ki az alkalmazást igényeinek megfelelően a preferált szállítóktól. Nem biztos a tábla kiválasztásában? Lépjen kapcsolatba velünk.',
  },
};

export default messages;

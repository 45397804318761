/* eslint-disable max-len */
const messages = {
  sk: {
    'application.text1': 'Oplatí sa mi to?',
    'application.text2': 'Prečo by som mal používať AppOn.cloud?',
    'application.text3': 'Ďalší softvér tohto výrobcu',
    'application.whytouse': 'Premium - Prečo by som mal používať AppOn.cloud premium?',
    'btn.casestudy': 'Prípadová štúdia',
    'btn.download': 'K stiahnutiu',
    'btn.send': 'Odoslať',
    'btn.video': 'Video',
    'contactform.formError': 'Pri odosielaní formulára sa vyskytla chyba',
    'contactform.formSent': 'Vážený zákazník, ďakujeme Vám za odoslanie dotazu. Ozveme sa Vám čo najskôr.',
    'contactform.gdpr': 'Súhlasím so spracovaním vyplnených údajov v rozsahu a na účely vymedzeným v Súhlase so ',
    'contactform.gdprError': 'required.',
    'contactform.gdprLink': 'spracovaním osobných údajov',
    'contactform.mail': 'Email',
    'contactform.message': 'Správa',
    'contactform.name': 'Meno',
    'contactform.phone': 'Telefón',
    'contactform.validation.email': 'Do e-mailovej adresy zahrňte znak @. V adrese chýba znak @.',
    'contactform.validation.phone': 'Zadajte medzinárodné telefónne číslo (+XXX XXX XXX XXX XXX)',
    'contacts.form.title': 'Máte otázku? napíšte nám',
    'contacts.sales': 'Obchod',
    'contacts.techsupport': 'Technická podpora',
    'contacts.title': 'Kontaktujte nás, máme zastúpenie v niekoľkých krajinách Európy',
    'cookies.preferences': 'Zmeniť predvoľby súborov cookie',
    'footer.sales': 'Obchod',
    'footer.techsupport': 'Technická podpora',
    'homepage.advantage.title': 'Prečo by som mal mať firemné aplikácie v cloude?',
    'homepage.form.title': 'oplatí sa mi to?',
    'homepage.introduction.button': 'Podporované aplikácie',
    'homepage.introduction.title': 'Najpohodlnejšie hosting vašich aplikácií',
    'homepage.support': 'Podporujeme',
    'homepage.supportedapps.title': 'Ktoré aplikácie podporujeme?',
    'langswitcher.country': 'Vyberte krajinu, v ktorej sa nachádzate',
    'langswitcher.lang': 'Vyberte jazyk',
    'navigation.contacts': 'Kontakty',
    'navigation.customerportal': 'Zákaznícky portál',
    'navigation.forpress': 'Pre médiá',
    'navigation.references': 'Referencie',
    'navigation.services': 'Služby',
    'navigation.support': 'Podpora ',
    'navigation.supportedapps': 'Podporované aplikácie',
    'premium.form.title': 'Máte záujem o AppOn.cloud premium? Napíšte nám a my Vás čoskoro kontaktujeme.',
    'premium.promo.cta': 'Mám záujem',
    'premium.promo.text': 'Ešte viac pohodlia pre Vašu firmu',
    'pressrelease.form.title': 'Oplatí sa mi to?',
    'pressrelease.text1': 'Zoznam článkov',
    'pressrelease.text2': 'Zoznam tlačových správ',
    'pressrelease.title': 'Tlačové správy',
    'reference.form.title': 'Rady spokojných zákazníkov využívajú naše služby. Pripojte sa k nim aj vy.',
    'reference.nextref': 'Ďalšie referencie',
    'search.label': 'Vyhľadávanie',
    'services.form.title': 'Zaujal vás tento produkt? Napíšte nám a my vás čoskoro kontaktujeme.',
    'services.status': 'Stav služieb',
    'services.title': 'Podporované služby',
    'supportedapps.subtitle':
      'Vyberte si aplikáciu podľa vašich potrieb od preferovaných dodávateľov. Neviete si s výberom rady? Kontaktujte nás.',
  },
};

export default messages;
